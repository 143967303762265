module.exports = {
  'language': 'en',
  'en': 'English',
  'ru': 'Русский',
  'company': 'AxxonSoft Ltd.',
  'companyUrl': 'https://www.axxonsoft.com/',
  'copyright': 'All rights reserved.',
  'Logotype': 'Logotype',
  'powered': 'Powered by',
  'UsageGuidelines': 'Usage guidelines',
  'Colors': 'Color palette',
  'UsageRestrictions': 'Usage restrictions',
  'Fonts': 'Fonts',
  'Download': 'Download',
  'Contents': 'Table of contents:',
  'AxxonNext': 'Axxon Next Logos',
  'LogoGroup': 'ITV GROUP Logos',
  'Next4': 'Axxon Next 4',
  'Intellect': 'Intellect',
  'Net': 'AxxonNet',
  'Brochures': 'Brochures',
  'Stationery': 'Stationery',
  'GraphicsForPublications': 'Graphics for publications',
  'Hybrid': '"Hybrid" type',
  'Island': '"Island" type',
  'WorkStations': 'Work Stations & Receptions',
  'DownloadMaterials': 'Download materials',
  'HowToDesignPostImage': 'How to design the post image?',
  'Vanguard': '"Vanguard" style',
  'Standard': '"Standard" style',
  'SpecialTemplates': 'Special Templates',
  'Post with a screenshot': 'Post with a screenshot',
  'Download mockups': 'Download mockups',
  'Download template': 'Download template',
  'Post with a product logo': 'Post with a product logo',
  'Post with a feature icon': 'Post with a feature icon',
  'Post about exhibition': 'Post about exhibition',
};