module.exports = {
  'language': 'ру',
  'en': 'English',
  'ru': 'Русский',
  'company': 'ITV GROUP.',
  'companyUrl': 'https://www.itv.ru/',
  'copyright': 'Дизайн-группа компании.',
  'Logotype': 'Логотипы',
  'powered': 'Разработано с помощью',
  'UsageGuidelines': 'Правила размещения',
  'Colors': 'Цветовая палитра',
  'LogosGuidelines': "ITVGROUP или ITV?",
  'UsageRestrictions': 'Неправильное использование',
  'Fonts': 'Шрифты',
  'Download': 'Скачать',
  'Contents': 'Содержание:',
  'AxxonNext': 'Логотипы Axxon Next ',
  'LogoGroup': 'Логотипы Ipdrom, Noder, Рубикон',
  'Next4': 'Axxon Next 4',
  'ITVCloud': 'ITV Cloud',
  'IntellectX': 'Intellect X',
  'Intellect': 'Intellect',
  'Net': 'AxxonNet',
  'Brochures': 'Брошюры',
  'Stationery': 'Канцелярские принадлежности',
  'GraphicsForPublications': 'Графика для публикаций',
  'Hybrid': "Многопрофильные стенды",
  'Island': "Специальные стенды",
  'WorkStations': 'Ресепшен и рабочие зоны',
  'DownloadMaterials': 'Материалы для скачивания',
  'HowToDesign': 'How to design the post image?',
  'HowToDesignPostImage': 'Как подготовить изображения для баннера?',
  'Vanguard': '«Авангардный» стиль',
  'Standard': '«Стандартный» стиль',
  'SpecialTemplates': 'Специальные шаблоны',
  'Post with a screenshot': 'Пост со скриншотом (продукта)',
  'Download mockups': 'Скачать макеты',
  'Download template': 'Скачать шаблон',
  'Post with a product logo': 'Пост с логотипом продукта',
  'Post with a feature icon': 'Пост с иконкой фичи продукта',
  'Post about exhibition': 'Анонс выставки',
};